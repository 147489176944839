<template lang="pug">
  .reports-wrapper
    .reports-table
      v-card
        .reports-table__header
          .reports-table__row.reports-table__row--header.reports-table__row--small
            .reports-table__column {{ 'base.date' | translate }}
            .reports-table__column {{ 'base.status' | translate }}
            .reports-table__column {{ 'base.recipe' | translate }}
            .reports-table__column {{ 'base.weight' | translate }} [{{ 'units.kg' | translate }}] #[br] {{ 'base.plan' | translate }} / {{ 'base.fact' | translate }}
            .reports-table__column {{ 'pages.reports.load_time' | translate }}

      v-table-body(
        v-if="!loading"
        outside-table
        gap
        :empty="!reports.length")

        reports-table-row(
          v-for="item in reports"
          :key="item.id"
          :item="item")

      reports-table-row-skeleton(
        v-else
        :count="6")

      template(
        v-if="!loading && showPagination")
        v-paginate(
          v-model="page"
          :page-count="pageCount"
          :click-handler="pageChangeHandler")

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import paginationMixin from '@/mixins/pagination.mixin'

import ReportsTableRow from './ReportsTableRow'
import ReportsTableRowSkeleton from './ReportsTableRowSkeleton'

export default {
  name: 'ReportsTable',

  mixins: [paginationMixin],

  components: {
    ReportsTableRow,
    ReportsTableRowSkeleton
  },

  computed: {
    ...mapGetters([
      'reports'
    ])
  },

  mounted () {
    this.setupPagination(this.fetchReports)
  },

  methods: {
    ...mapActions(['fetchReports'])
  }
}
</script>

<style lang="scss">
  @import '@/sass/abstracts/_variables';

  .reports-table {
    font-size: 1.5rem;
    line-height: 1.8rem;

    &__row {
      position: relative;
      padding: 1.5rem;
      display: flex;
      align-items: center;

      &--small {
        padding: .8rem 1.5rem;
      }

      &--header {
        .reports-table {
          &__column {
            color: $color-gray;
          }
        }
      }

      &--body {
        .reports-table {
          &__column {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 20rem;
          }
        }
      }
    }

    &__header {
      margin-bottom: 1.5rem;
    }

    &__column {
      padding-right: 1rem;

      &:nth-child(1) {
        flex: 1 1 15%;
      }

      &:nth-child(2) {
        flex: 1 1 18%;
      }

      &:nth-child(3) {
        flex: 1 1 25%;
      }

      &:nth-child(4) {
        flex: 1 1 15%;
      }

      &:nth-child(5) {
        flex: 1 1 10%;
        padding-right: 0;
      }
    }

    &__list {
      position: relative;
      padding: 1.35rem 5.6rem;
      display: flex;
      max-width: 37rem;
      width: 100%;

      &::after {
        content: '';
        position: absolute;
        background-color: $color-gray-light-2;
        height: .1rem;
        width: 55%;
        bottom: 0;
      }

      &:nth-child(2) {

        &::before {
          content: '';
          position: absolute;
          background-color: $color-gray-light-2;
          height: .1rem;
          max-width: 78.5rem;
          width: 220%;
          top: 0;
          left: 1rem;
        }
      }

      &:last-child {
        padding-bottom: 2.9rem;

        &::after {
          bottom: 1.5rem;
        }
      }
    }

    &__list-empty {
      padding: 1.5rem 1.8rem;
      text-align: center;
      border-top: 1px solid $table-border-color;
    }

    &__item {

      &:first-child {
        margin-right: auto;
      }

      &:last-child {
        margin-left: 3rem;
      }
    }
  }
</style>
